import { FormDialogService } from '@rcg/forms';
import { tr } from '@rcg/intl';
import { MessageService, getContrastYIQ, normalizeColorToHex, randomClassName } from '@rcg/standalone';
import { EJ2Instance, PopupCloseEventArgs, PopupOpenEventArgs, ScheduleComponent } from '@syncfusion/ej2-angular-schedule';
import { Dialog as SFDialog, Popup as SFPopup } from '@syncfusion/ej2-popups';
import * as dot from 'dot-object';
import { tz } from 'moment-timezone';
import { firstValueFrom } from 'rxjs';
import { ICalendarService, RcgCalendarAcl, RcgCalendarView, RcgEvent, RcgView } from '../models';

const modAttribute = 'rcg-modified';
const modOldElementField = '_rcg_old_element';
const modRemoveElementField = '_rcg_remove_element';

type ModOldElement = HTMLElement & { [modOldElementField]?: HTMLElement };
type ModRemoveElement = HTMLElement & { [modRemoveElementField]?: true };

export class RcgSFPopupUtil {
  constructor(
    private getSchedule: () => ScheduleComponent,
    private service: ICalendarService,
    private messageService: MessageService,
    private formDialogService: FormDialogService,
    private addUpdatingEvents: (records: Record<string, unknown>[]) => void,
    private getResourceGroupAssignment: (data: RcgEvent, groupId: number) => number | null | undefined,
    private getExtraEventInfoPopupButtons?: (
      args: PopupOpenEventArgs,
      acl: RcgCalendarAcl,
    ) => {
      title?: Promise<string | undefined> | string | undefined;
      icon?: Promise<string | undefined> | string | undefined;
      disabled?: Promise<boolean> | boolean | undefined;
      closePopup?: Promise<boolean> | boolean | undefined;
      callback: () => void;
    }[],
  ) {}

  private destroyEJ2(element: HTMLElement) {
    const instances = (element as EJ2Instance).ej2_instances;
    if (!Array.isArray(instances)) return;

    for (const i of instances) {
      if (!i.destroy) continue;
      try {
        i.destroy();
      } catch (error) {
        console.error('Failed to destroy popup EJ2 instance:', error);
      }
    }
  }

  private cleanupPopupRecurrenceEditor(args: PopupCloseEventArgs, fieldName: string) {
    const recurElement = args.element.querySelector<HTMLElement>(`#${fieldName}`);
    if (!recurElement) return;

    this.destroyEJ2(recurElement);
  }

  private closePopup(args: PopupOpenEventArgs) {
    const instances = (args.element as EJ2Instance | undefined)?.ej2_instances?.filter(
      (e) => e instanceof SFDialog || e instanceof SFPopup,
    ) as (SFDialog | SFPopup)[] | undefined;

    for (const i of instances ?? []) i.hide();
  }

  private setupAlertPopup(
    args: PopupOpenEventArgs,
    oldDialogContent: HTMLDivElement,
    oldButton: HTMLButtonElement,
    contentTr: string,
    sourceButtons: (readonly [string, () => void])[],
  ) {
    if (!sourceButtons.length) throw 'Cannot set uo delete alert without buttons!';

    const newDialogContent = oldDialogContent.cloneNode(true) as HTMLDivElement;

    firstValueFrom(tr(contentTr)).then((tr) => (newDialogContent.innerText = tr));
    newDialogContent.setAttribute(modAttribute, args.type);

    const buttons = sourceButtons.map(([textTr, callback]) => {
      const button = oldButton.cloneNode(true) as HTMLButtonElement;

      firstValueFrom(tr(textTr)).then((tr) => (button.innerText = tr));
      button.addEventListener('click', callback);
      button.setAttribute(modAttribute, args.type);

      (button as ModRemoveElement)[modRemoveElementField] = true;

      return button;
    });

    const leftButtons = buttons.slice(0, -1).reverse();
    const lastButton = buttons[buttons.length - 1];

    oldDialogContent.replaceWith(newDialogContent);

    for (const b of leftButtons) oldButton.parentElement!.insertBefore(b, oldButton);
    oldButton.replaceWith(lastButton);

    (newDialogContent as ModOldElement)[modOldElementField] = oldDialogContent;
    (lastButton as ModOldElement)[modOldElementField] = oldButton;
  }

  public openNewRecurrenceExceptionEditor(record: Record<string, unknown>) {
    const data = { ...record };

    data.ExceptionForId = data.Id;

    delete data.Id;
    delete data.SFEventId;

    delete data.Recurrence;
    delete data.RecurrenceException;
    delete data.RecurrenceID;

    delete data.rruleset;

    this.getSchedule().eventWindow.openEditor(data, 'Add', true);
  }

  private openSeriesEditor(record: Record<string, unknown>) {
    const data = record;

    const rruleset = data.rruleset as { dtstart: string; dtend: string };
    const resourceTimezone = data.ResourceTimezone as string | undefined;

    data.StartTime = tz(rruleset.dtstart, resourceTimezone ?? 'Etc/UTC').toDate();
    data.EndTime = tz(rruleset.dtend, resourceTimezone ?? 'Etc/UTC').toDate();

    this.getSchedule().eventWindow.openEditor(data, 'EditSeries', true);
  }

  private setupRecurrenceAlertPopup(args: PopupOpenEventArgs, acl: RcgCalendarAcl) {
    if (!this.getSchedule().eventSettings.allowEditing) {
      args.cancel = true;
      return;
    }

    const event = args.data as RcgEvent;
    const aclIds = [...(acl.id ? [acl.id] : []), ...(acl.ids ?? [])];

    const canEditSeries = acl.edit_series && (!event.edit_acl_ids || event.edit_acl_ids.some((id) => aclIds.includes(id)));
    const canAddOccurrenceException =
      acl.edit_occurrence &&
      (!event.add_occurrence_exception_acl_ids || event.add_occurrence_exception_acl_ids.some((id) => aclIds.includes(id)));

    if (!canEditSeries && !canAddOccurrenceException) {
      firstValueFrom(tr('calendar_no_permission_to_edit_event')).then((t) => this.messageService.showErrorSnackbar(t));

      args.cancel = true;
      return;
    }

    if (canEditSeries && !canAddOccurrenceException) {
      this.openSeriesEditor(args.data!);

      args.cancel = true;
      return;
    }

    if (canAddOccurrenceException && !canEditSeries) {
      this.openNewRecurrenceExceptionEditor(args.data!);

      args.cancel = true;
      return;
    }

    const oldOccurrenceBtn = args.element.querySelector<HTMLButtonElement>('button.e-quick-dialog-occurrence-event');
    const oldSeriesBtn = args.element.querySelector<HTMLButtonElement>('button.e-quick-dialog-series-event');

    if (!oldOccurrenceBtn || !oldSeriesBtn) throw 'Invalid elements';
    if (oldOccurrenceBtn.getAttribute(modAttribute) || oldSeriesBtn.getAttribute(modAttribute)) return;

    const newOccurrenceBtn = oldOccurrenceBtn.cloneNode(true) as HTMLButtonElement;
    const newSeriesBtn = oldSeriesBtn.cloneNode(true) as HTMLButtonElement;

    newOccurrenceBtn.addEventListener('click', () => {
      this.closePopup(args);
      this.openNewRecurrenceExceptionEditor(args.data!);
    });

    newSeriesBtn.addEventListener('click', () => {
      this.closePopup(args);
      this.openSeriesEditor(args.data!);
    });

    oldOccurrenceBtn.replaceWith(newOccurrenceBtn);
    oldSeriesBtn.replaceWith(newSeriesBtn);

    (newOccurrenceBtn as ModOldElement)[modOldElementField] = oldOccurrenceBtn;
    (newSeriesBtn as ModOldElement)[modOldElementField] = oldSeriesBtn;

    newOccurrenceBtn.setAttribute(modAttribute, args.type);
    newSeriesBtn.setAttribute(modAttribute, args.type);
  }

  private setupRecurringDeleteAlertPopup(
    args: PopupOpenEventArgs,
    oldDialogContent: HTMLDivElement,
    oldDeleteBtn: HTMLButtonElement,
    view: RcgView,
    acl: RcgCalendarAcl,
  ) {
    const event = args.data as RcgEvent;
    const aclIds = [...(acl.id ? [acl.id] : []), ...(acl.ids ?? [])];

    let canDeleteSeries = acl.delete_series;
    let canDeleteOccurrence = acl.delete_occurrence;

    if (event.delete_acl_ids) {
      const canDelete = event.delete_acl_ids.some((id) => aclIds.includes(id));

      canDeleteSeries = canDeleteSeries && canDelete;
      canDeleteOccurrence = canDeleteOccurrence && canDelete;
    }

    if (!canDeleteSeries && !canDeleteOccurrence) {
      firstValueFrom(tr('calendar_no_permission_to_delete_event')).then((t) => this.messageService.showErrorSnackbar(t));

      args.cancel = true;
      return;
    }

    const addDeleteException = async () => {
      this.closePopup(args);

      try {
        await this.service.addEvent(
          view,
          {
            ...args.data,
            ExceptionForId: args.data!.Id,
          },
          true,
        );
      } catch (error) {
        console.error('Failed to add delete exception', error);

        const msg = (await firstValueFrom(tr('calendar_add_delete_exception_error'))) + ':';
        this.messageService.showErrorSnackbar(msg, error, 10);
      }
    };

    const messageTr =
      canDeleteSeries && canDeleteOccurrence
        ? 'calendar_delete_series_or_single_event_message'
        : canDeleteSeries
        ? 'calendar_delete_series_alert_message'
        : 'calendar_delete_single_event_alert_message';

    this.setupAlertPopup(args, oldDialogContent, oldDeleteBtn, messageTr, [
      ...(canDeleteSeries ? [['calendar_delete_series', () => oldDeleteBtn.click()] as const] : []),
      ...(canDeleteOccurrence ? [['calendar_delete_event', () => addDeleteException()] as const] : []),
    ]);
  }

  private setupExceptionDeleteAlertPopup(
    args: PopupOpenEventArgs,
    oldDialogContent: HTMLDivElement,
    oldDeleteBtn: HTMLButtonElement,
    view: RcgView,
    acl: RcgCalendarAcl,
  ) {
    if (!acl.delete_occurrence) {
      firstValueFrom(tr('calendar_no_permission_to_delete_occurrence')).then((t) => this.messageService.showErrorSnackbar(t));

      args.cancel = true;
      return;
    }

    const addDeleteException = async () => {
      this.closePopup(args);

      try {
        await this.service.updateEvent(view, args.data!, true);
      } catch (error) {
        console.error('Failed to add delete exception', error);

        const msg = (await firstValueFrom(tr('calendar_add_delete_exception_error'))) + ':';
        this.messageService.showErrorSnackbar(msg, error, 10);
      }
    };

    this.setupAlertPopup(args, oldDialogContent, oldDeleteBtn, 'calendar_delete_event_restore_or_create_exception_message', [
      ['calendar_delete_event', () => oldDeleteBtn.click()],
      ['calendar_create_exception', () => addDeleteException()],
    ]);
  }

  private setupDeleteAlertPopup(args: PopupOpenEventArgs, view: RcgView, acl: RcgCalendarAcl) {
    if (!args.data?.IsRecurring && !args.data?.ExceptionForId) {
      if (!acl.delete_occurrence) {
        firstValueFrom(tr('calendar_no_permission_to_delete_occurrence')).then((t) => this.messageService.showErrorSnackbar(t));

        args.cancel = true;
      }

      return;
    }

    const oldDialogContent = args.element.querySelector<HTMLDivElement>('#QuickDialog_dialog-content');
    const oldDeleteBtn = args.element.querySelector<HTMLButtonElement>('button.e-quick-dialog-delete');

    if (!oldDialogContent || !oldDeleteBtn) throw 'Invalid elements';
    if (oldDialogContent.getAttribute(modAttribute) || oldDeleteBtn.getAttribute(modAttribute)) return;

    if (args.data?.IsRecurring) return this.setupRecurringDeleteAlertPopup(args, oldDialogContent, oldDeleteBtn, view, acl);
    if (args.data?.ExceptionForId) return this.setupExceptionDeleteAlertPopup(args, oldDialogContent, oldDeleteBtn, view, acl);

    console.error('Failed to set up delete alert popup:', args);
  }

  private setupEditorPopup(
    args: PopupOpenEventArgs,
    view: RcgView,
    acl: RcgCalendarAcl,
    readonlyFields: RcgCalendarView['readonly_fields'] | undefined,
    hiddenFields: RcgCalendarView['hidden_fields'] | undefined,
  ) {
    args.cancel = true;

    const event = args.data as RcgEvent;

    const isAdd = !args.data?.Id;
    const isException = args.data?.ExceptionForId;
    const aclIds = [...(acl.id ? [acl.id] : []), ...(acl.ids ?? [])];

    if (isAdd && isException) {
      if (event.add_occurrence_exception_acl_ids && !event.add_occurrence_exception_acl_ids.some((id) => aclIds.includes(id))) {
        firstValueFrom(tr('calendar_no_permission_to_add_exceptions')).then((t) => this.messageService.showErrorSnackbar(t));
        return;
      }
    } else if (isAdd && !acl.add) {
      firstValueFrom(tr('calendar_no_permission_to_add_events')).then((t) => this.messageService.showErrorSnackbar(t));
      return;
    } else if (!isAdd) {
      //? Edit event

      if (event.Recurrence) {
        if (!acl.edit_series) {
          firstValueFrom(tr('calendar_no_permission_to_edit_series')).then((t) => this.messageService.showErrorSnackbar(t));

          return;
        }
      } else {
        if (!acl.edit_occurrence) {
          firstValueFrom(tr('calendar_no_permission_to_edit_occurrence')).then((t) => this.messageService.showErrorSnackbar(t));
          return;
        }
      }

      if (event.edit_acl_ids && !event.edit_acl_ids.some((id) => aclIds.includes(id))) {
        firstValueFrom(tr('calendar_no_permission_to_edit_events')).then((t) => this.messageService.showErrorSnackbar(t));
        return;
      }
    }

    (async () => {
      const dialogTitle = await firstValueFrom(
        tr(args.data?.ExceptionForId ? 'recurring_event_exception' : args.data?.IsRecurring ? 'recurring_event' : 'event'),
      );

      const resourceGroups = view.resource_groups;

      const assignments = Object.fromEntries(
        await Promise.all(
          resourceGroups.map(async (rg, i) => {
            const resourceId = this.getResourceGroupAssignment(event, rg.id);
            const resource = await firstValueFrom(rg.resourceById(resourceId));

            return [
              '__resGrpAss_' + i,
              resource
                ? {
                    id: resourceId,
                    name: resource.name,
                    color: resource.color,
                  }
                : null,
            ];
          }),
        ),
      );

      const data = {
        ...event,
        __debug: !!window.localStorage.getItem('rcg_calendar_form_debug'),
        __acl: acl,
        __readonlyFields: readonlyFields ?? [],
        __hiddenFields: hiddenFields?.editor ?? [],
        __resGrp: resourceGroups,
        __view: view,
        ...assignments,
      };

      const today = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][event.StartTime.getDay()];

      const formSettings = Object.entries(view.form_settings?.byDay ?? {}).flatMap(([days, settings]) =>
        days.split(',').includes(today) ? [settings as typeof settings | undefined] : [],
      )[0];
      let formPath = formSettings?.path;
      if (formSettings?.path !== undefined) {
        if (formSettings?.choose_form === true) {
          const dialogResult = await this.messageService.confirmDialogAsync({
            icon: 'supervised_user_circle',
            title: 'izbira obrazca',
            message: formSettings?.message_text ?? 'Želite vnesti rojstni dan ali termin?',
            cancelText: 'Termin',
            confirmText: formSettings?.form_name ?? 'rojstni dan',
          });

          if (dialogResult !== true) {
            formPath = 'calendar/event/generic';
          }
        }
      }

      this.formDialogService.openForm({
        dialogTitle,
        formId: formPath ?? 'calendar/event/generic',
        formMode: 'insert', //? Always insert - prevents existing data query
        prefillData: data,
        onFormSubmited: async (payload, closeDialog) => {
          const modelEvent = payload.model as unknown as Partial<RcgEvent>;

          const assignments = (['__resGrpAss_', '__resGrp_'] as const)
            .map((rgPrefix) =>
              Object.fromEntries(
                Object.entries(payload.model)
                  .filter(([key]) => key.startsWith(rgPrefix))
                  .map(([key, value]) => {
                    const resGrpIndex = parseInt(key.substring(rgPrefix.length));
                    const resGrp = resourceGroups[resGrpIndex];

                    const resourceIdVal = value as string | { id: unknown } | { value: unknown } | null | undefined;
                    const resourceId =
                      typeof resourceIdVal === 'string'
                        ? null
                        : !resourceIdVal
                        ? undefined
                        : 'id' in resourceIdVal
                        ? resourceIdVal.id
                        : resourceIdVal.value;

                    return ['ResourceGroup_' + resGrp.id, resourceId];
                  }),
              ),
            )
            .reduce((acc, curr) => ({ ...acc, ...curr }), {});

          const newEventData = {
            ...modelEvent,
            ...assignments,
            ExtResourceGroups: resourceGroups.filter((rg) => rg.ext_config),
          };

          this.addUpdatingEvents([newEventData]);

          try {
            if (isAdd) await this.service.addEvent(view, newEventData);
            else await this.service.updateEvent(view, newEventData);

            closeDialog();
          } catch (error) {
            if (error instanceof Error && error?.name === 'ApolloError') {
              const jsonDetailMessage = JSON.parse(JSON.stringify(error));
              if (
                jsonDetailMessage?.cause?.extensions?.code === 'data-exception' ||
                jsonDetailMessage?.cause?.extensions?.code?.startsWith('4')
              ) {
                this.messageService.showErrorSnackbar('', jsonDetailMessage?.message, 5);
              } else {
                console.error(`Failed to ${isAdd ? 'add' : 'update'} event:`, error);

                const msg = (await firstValueFrom(tr(`calendar_event_${isAdd ? 'add' : 'update'}_error`))) + ':';
                this.messageService.showErrorSnackbar(msg, error, 10);
              }
            } else {
              console.error(`Failed to ${isAdd ? 'add' : 'update'} event:`, error);

              const msg = (await firstValueFrom(tr(`calendar_event_${isAdd ? 'add' : 'update'}_error`))) + ':';
              this.messageService.showErrorSnackbar(msg, error, 10);
            }
          }
        },
      });
    })();
  }

  private _eventInfoPopupButton(
    args: PopupOpenEventArgs,
    src: HTMLButtonElement,
    title: Promise<string | undefined> | string | undefined,
    icon: Promise<string | undefined> | string | undefined,
    disabled: Promise<boolean> | boolean,
    callback: () => void,
  ) {
    const button = src.cloneNode(true) as HTMLButtonElement;
    const iconContainer = button.querySelector('.e-icons')! as HTMLElement;

    (button as HTMLElement as EJ2Instance).ej2_instances = []; //? Workaround for unsafe access bug when popup is destroyed

    (async () => {
      const t = await title;
      if (t) button.title = t;

      const i = await icon;
      if (i) {
        iconContainer.classList.remove('e-edit-icon');
        iconContainer.innerText = i;
      }

      const d = await disabled;
      button.disabled = d;
      if (d) iconContainer.style.opacity = '0.5';
    })();

    button.addEventListener('click', () => callback());

    (button as ModRemoveElement)[modRemoveElementField] = true;
    button.setAttribute(modAttribute, args.type);

    return button;
  }

  private setupEventInfoPopup(args: PopupOpenEventArgs, acl: RcgCalendarAcl) {
    if (!args.data?.Id) {
      //? Clicked on blank cell
      args.cancel = true;
      return;
    }

    const event = args.data as RcgEvent;
    const aclIds = [...(acl.id ? [acl.id] : []), ...(acl.ids ?? [])];

    const canEditSeries =
      (!event.Recurrence && acl.edit_occurrence) ||
      (acl.edit_series && (!event.edit_acl_ids || event.edit_acl_ids.some((id) => aclIds.includes(id))));

    const canAddOccurrenceException =
      acl.edit_occurrence &&
      (!event.add_occurrence_exception_acl_ids || event.add_occurrence_exception_acl_ids.some((id) => aclIds.includes(id)));

    const canDelete =
      (acl.delete_occurrence || acl.delete_series) && (!event.delete_acl_ids || event.delete_acl_ids.some((id) => aclIds.includes(id)));

    const ogEditSeriesButton = args.element.querySelector<HTMLButtonElement>('button.e-edit');
    if (!ogEditSeriesButton) throw 'Invalid elements';

    const customButtons: HTMLButtonElement[] = [];

    const extraButtons = this.getExtraEventInfoPopupButtons?.(args, acl) ?? [];

    for (const { title, icon, disabled, closePopup, callback } of extraButtons) {
      const button = this._eventInfoPopupButton(args, ogEditSeriesButton, title, icon, disabled ?? false, () => {
        (async () => {
          if ((await closePopup) ?? true) this.closePopup(args);
        })();

        callback();
      });

      customButtons.push(button);
    }

    const extraEditButtons: HTMLButtonElement[] = [];

    if (!event.ExceptionForId) {
      if (canEditSeries) {
        const editSeriesButton = this._eventInfoPopupButton(
          args,
          ogEditSeriesButton,
          event.Recurrence ? firstValueFrom(tr('calendar_edit_series')) : undefined,
          undefined,
          false,
          () => {
            this.closePopup(args);
            this.openSeriesEditor(args.data!);
          },
        );

        customButtons.push(editSeriesButton);
        extraEditButtons.push(editSeriesButton);
      }

      if (canAddOccurrenceException && event.IsRecurring) {
        const editNewRecurrenceExceptionButton = this._eventInfoPopupButton(
          args,
          ogEditSeriesButton,
          firstValueFrom(tr('calendar_create_exception')),
          '',
          ogEditSeriesButton.disabled,
          () => {
            this.closePopup(args);
            this.openNewRecurrenceExceptionEditor(args.data!);
          },
        );

        customButtons.push(editNewRecurrenceExceptionButton);
        extraEditButtons.push(editNewRecurrenceExceptionButton);
      }
    }

    const buttonParent = ogEditSeriesButton.parentElement!;

    for (const btn of customButtons) {
      buttonParent.insertBefore(btn, ogEditSeriesButton);
    }

    if (!event.ExceptionForId) {
      ogEditSeriesButton.style.display = 'none';
    }

    const eventSettings = this.getSchedule().eventSettings;

    const hideButtons = [
      ...(eventSettings.allowEditing ? [] : [ogEditSeriesButton, ...extraEditButtons]),
      ...(eventSettings.allowDeleting && canDelete
        ? []
        : args.element.querySelectorAll<HTMLButtonElement>('.e-header-icon-wrapper .e-delete')),
    ];

    for (const btn of hideButtons) {
      btn.style.display = 'none';
    }

    window.setTimeout(() => {
      const header = args.element.querySelector<HTMLDivElement>('.e-popup-header');
      if (!header || !header.style.backgroundColor) return;

      const className = randomClassName();
      const style = args.element.appendChild(document.createElement('style'));
      const sheet = style.sheet!;

      const color = getContrastYIQ(normalizeColorToHex(header.style.backgroundColor));

      header.classList.add(className);
      sheet.insertRule(`.${className} * { color: ${color} !important; }`);
    });

    const popups = (args.element as unknown as { ej2_instances: unknown[] } | undefined)?.ej2_instances?.filter(
      (e) => e instanceof SFPopup,
    ) as SFPopup[] | undefined;

    if (popups) {
      const resizeObserver = new ResizeObserver(() => {
        for (const popup of popups) {
          popup.refreshPosition();
        }
      });

      resizeObserver.observe(args.element);

      const cleanup = () => {
        resizeObserver.disconnect();
      };

      for (const popup of popups) {
        popup.addEventListener('close', cleanup);
      }
    }
  }

  private closePopups() {
    const openPopups = document.querySelectorAll('#_popup.e-popup-open');

    for (const popup of openPopups) {
      popup.classList.remove('e-popup-open');
      popup.classList.add('e-popup-close');
    }
  }

  private openCustomForm(args: PopupOpenEventArgs, view: RcgView, acl: RcgCalendarAcl) {
    switch (args.type) {
      case 'Editor':
        {
          args.cancel = true;
          const event = args.data as RcgEvent;
          const isAdd = !args.data?.Id;
          const formSettings = view.custom_form_settings;

          if (isAdd) {
            if (!formSettings?.insert) return;
            const createNestedObject = (path: string, value: unknown) =>
              !path.includes('.') ? { [path]: value } : dot.str(path, value, {});
            const startEndDt = {
              ...createNestedObject(formSettings!.insert!.startDatePath, event.StartTime),
              ...createNestedObject(formSettings!.insert!.endDatePath, event.EndTime),
            };
            this.formDialogService.openForm({
              formMode: 'insert',
              dialogIcon: view.custom_form_settings?.insert?.icon,
              dialogTitle: view.custom_form_settings?.insert?.dialogTitle,
              formId: view.custom_form_settings?.formId,
              prefillData: view.custom_form_settings?.insert?.prefill
                ? { ...view.custom_form_settings?.insert.prefill, ...startEndDt }
                : startEndDt,
              onSubmitSuccessAction: () => {
                setTimeout(() => {
                  // hasura bug insert and get event in short time, must have timeout
                  (this.service as unknown as { refresh(): void | undefined })?.refresh();
                }, 1000);
              },
            });
          } else {
            if (!formSettings?.edit) return;
            this.formDialogService.openForm({
              formMode: 'update',
              formrecordId: event?.Id,
              dialogIcon: view.custom_form_settings?.edit?.icon,
              dialogTitle: view.custom_form_settings?.edit?.dialogTitle,
              formId: view.custom_form_settings?.formId,
              prefillData: view.custom_form_settings?.edit?.prefill,
              onSubmitSuccessAction: () => {
                (this.service as unknown as { refresh(): void | undefined })?.refresh();
              },
            });
          }
        }
        break;
      case 'QuickInfo':
      case 'ViewEventInfo':
        this.setupEventInfoPopup(args, acl);
        break;
      default:
        console.warn('[RcG SF popup util]', 'Unhandled popup type:', args.type, args);
        break;
    }
  }

  //! Public functions

  public onPopupOpen(
    args: PopupOpenEventArgs,
    view: RcgView,
    acl: RcgCalendarAcl,
    readonlyFields: RcgCalendarView['readonly_fields'] | undefined,
    hiddenFields: RcgCalendarView['hidden_fields'] | undefined,
  ) {
    // handle custom form
    if (view.custom_form_settings) {
      this.openCustomForm(args, view, acl);
      return;
    }

    switch (args.type) {
      case 'RecurrenceAlert':
        this.setupRecurrenceAlertPopup(args, acl);
        break;
      case 'DeleteAlert':
        this.setupDeleteAlertPopup(args, view, acl);
        break;
      case 'Editor':
        this.setupEditorPopup(args, view, acl, readonlyFields, hiddenFields);
        break;
      case 'QuickInfo':
      case 'ViewEventInfo':
        this.setupEventInfoPopup(args, acl);
        break;
      default:
        console.warn('[RcG SF popup util]', 'Unhandled popup type:', args.type, args);
        break;
    }
  }

  public onPopupClose(args: PopupCloseEventArgs) {
    const elements = args.element.querySelectorAll(`[${modAttribute}]`);

    for (const e of elements) {
      try {
        const oldElement = (e as ModOldElement)[modOldElementField];
        if (oldElement) e.replaceWith(oldElement);

        const removeElement = (e as ModRemoveElement)[modRemoveElementField];
        if (removeElement) e.remove();
      } catch (error) {
        console.error('Error while replacing popup button on close:', error);
      }
    }

    switch (args.type) {
      case 'Editor':
        try {
          this.cleanupPopupRecurrenceEditor(args, 'Recurrence');
          this.cleanupPopupRecurrenceEditor(args, 'RecurrenceException');

          this.closePopups();
        } catch (error) {
          console.error('Editor popup cleanup error:', error);
        }
        break;
    }
  }
}
