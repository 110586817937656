import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { AuthService } from '@rcg/auth';
import {
  CalendarContainerComponent,
  CustomFormSettings,
  ICalendarService,
  RcgCalendarEventException,
  RcgEvent,
  RcgResource,
  RcgView,
} from '@rcg/calendar';
import { GraphqlClientService } from '@rcg/graphql';
import { tr } from '@rcg/intl';
import { gql } from 'apollo-angular';
import * as dot from 'dot-object';
import { getOperationAST } from 'graphql';
import { DateTime } from 'luxon';
import { derivedAsync } from 'ngxtension/derived-async';
import { catchError, firstValueFrom, map, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { CalendarFormSettings, EventData, GqlCalendarConfig, MapEventData } from './models';

export class GqlSimpleCalendarService implements ICalendarService {
  private readonly gqlClient = inject(GraphqlClientService);
  private readonly auth = inject(AuthService);
  private readonly refreshTrigger = new Subject<void>();

  readonly config = signal<GqlCalendarConfig | undefined>(undefined);
  readonly config$ = toObservable(this.config);

  getEvents(_view: RcgView, resourceGroupId: number): Observable<RcgEvent[]> {
    return merge(this.config$, this.refreshTrigger.pipe(switchMap(() => this.config$))).pipe(
      switchMap((config) => {
        if (!config) {
          return of([]);
        }
        const query = typeof config.query === 'string' ? gql(config.query) : config.query;
        const operationAST = getOperationAST(query);
        if (!operationAST) {
          throw new Error('Invalid GraphQL document');
        }

        const isQuery = operationAST.operation === 'query';
        const isSubscription = operationAST.operation === 'subscription';
        if (!isQuery && !isSubscription) {
          throw new Error('Unsupported operation type - must be query or subscription');
        }
        return (
          isQuery
            ? this.gqlClient.query<{ data: Record<string, unknown>[] }>({
                query: query,
                variables: this.resolveVariables(config?.variables),
              })
            : this.gqlClient.subscribe<{ data: Record<string, unknown>[] }>({
                query: query,
                variables: this.resolveVariables(config?.variables),
              })
        ).pipe(
          map((response) => {
            return (response?.data ?? []).map((d) => {
              if (!d || !config.eventData) return null;
              const event = this.mapEvent(d, config.eventData);
              if (!event?.id || !event?.start || !event?.end) {
                return null;
              }

              const startDate = DateTime.fromISO(event.start).toLocal().toJSDate();
              const endDate = DateTime.fromISO(event.end).toLocal().toJSDate();

              const eventResult = {
                Id: event.id as number,
                Subject: event.title ?? '',
                Description: event.description ?? '',
                Comment: undefined,
                ResourceGroupId: resourceGroupId,
                ResourceId: 1,
                OriginalResourceId: 1,
                StartTime: startDate,
                EndTime: endDate,
                rruleset: {
                  dtstart: startDate.toISOString(),
                  dtend: endDate.toISOString(),
                  rrule: null,
                  exrule: null,
                  exdate: null,
                  rdate: null,
                },
                ParentResourceId: 1,
                ResourceChildren: [],
                AssignedToParentResource: false,
                RecurrenceID: undefined,
                RecurrenceException: null,
                Recurrence: null,
                IsRecurring: false,
                IsBlock: false,
                ExceptionForId: undefined,
                Exceptions: [],
                Report: undefined,
                Color: event.color?.replace(/^\\x/, '#'),
                status: null,
                ExtResourceGroups: [],
                edit_acl_ids: [],
                delete_acl_ids: [],
                add_occurrence_exception_acl_ids: [],
                ResourceGroup_1: 1,
              } as RcgEvent;
              return eventResult;
            });
          }),
          map((events) => events.filter((event): event is RcgEvent => event !== null)),
          catchError((error) => {
            console.error('Error fetching events', error);
            return of([]);
          }),
        );
      }),
    );
  }

  private mapEvent(data: Record<string, unknown>, eventData: MapEventData): EventData | null {
    if (!eventData) return null;
    return {
      id: eventData.id ? dot.pick(eventData.id, data) : null,
      title: eventData.title ? dot.pick(eventData.title, data) : '',
      description: eventData.description ? dot.pick(eventData.description, data) : null,
      start: eventData.start ? dot.pick(eventData.start, data) : null,
      end: eventData.end ? dot.pick(eventData.end, data) : null,
      color: eventData.color ? dot.pick(eventData.color, data) : null,
      startTimezone: eventData.startTimezone ? dot.pick(eventData.startTimezone, data) : null,
      endTimezone: eventData.endTimezone ? dot.pick(eventData.endTimezone, data) : null,
    };
  }

  private resolveVariables(variables?: Record<string, unknown>): Record<string, unknown> {
    if (!variables) {
      return {};
    }

    return {
      ...variables,
      ...Object.entries(variables)
        .filter(([, v]) => typeof v === 'string' && v.startsWith('$'))
        .reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: (v as string).startsWith('$tenantId')
              ? this.auth.tenant()?.id
              : (v as string).startsWith('$userId')
              ? this.auth.user()?.id
              : (v as string).startsWith('$organizationId')
              ? this.auth.tenant()?.organization?.id
              : v,
          }),
          {},
        ),
    };
  }

  getEventExceptions(): Observable<RcgCalendarEventException[]> {
    return of([]);
  }

  addEvent(): Promise<void> {
    throw new Error('Event add not implemented.');
  }

  updateEvent(): Promise<void> {
    throw new Error('Event update not implemented.');
  }

  deleteEvent(): Promise<void> {
    throw new Error('Event delete not implemented.');
  }

  refresh(): void {
    this.refreshTrigger.next();
  }
}

@Component({
  selector: 'rcg-gql-calendar',
  standalone: true,
  imports: [CalendarContainerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ICalendarService,
      useClass: GqlSimpleCalendarService,
    },
  ],
  template: `<rcg-calendar-container [viewData]="viewData()"></rcg-calendar-container>`,
})
export class GqlCalendarComponent {
  readonly config = input<GqlCalendarConfig>();

  private readonly calendarService = inject(ICalendarService);

  constructor() {
    effect(
      () => {
        (this.calendarService as GqlSimpleCalendarService).config.set(this.config());
      },
      { allowSignalWrites: true },
    );
  }

  readonly viewData = derivedAsync<RcgView>(
    async () => {
      const resource: RcgResource = {
        parent_id: null,
        id: 1,
        name: '',
        description: '',
        color: '#000000',
        children: [],
        isParent: true,
        active: true,
        timezone: 'Europe/Ljubljana',
      };

      const view = {
        id: 1,
        name: 'Simple Calendar',
        description: 'Simple calendar view',
        icon: 'event',
        allowed_view_types: this.config()?.allowed_view_types?.length
          ? this.config()!.allowed_view_types!
          : ['Day', 'Week', 'Month', 'Agenda', 'TimelineMonth', 'TimelineWeek', 'TimelineWorkWeek', 'TimelineDay'],
        color_resource_group_id: 2,
        default_view_type: 'Month',
        interval: '01:00:00',
        mobile_default_view_type: null,
        mobile_view: null,
        readonly_fields: null,
        slots: 2,
        spanned_event_placement: 'TimeSlot',
        working_days: [1, 2, 3, 4, 5],
        working_hours: ['00:00:00', '24:00:00'],
        resource_groups: [
          {
            id: 1,
            name: 'Resource',
            description: 'Resource',
            allResources: () => of([resource]),
            searchResources: () => of([resource]),
            resourceById: () => of(resource),
          },
        ],
        acl: {
          add: false as boolean,
          edit_series: false as boolean,
          edit_occurrence: false,
          resize: false,
          drag: false,
          delete_series: false,
          delete_occurrence: false,
          recurrence: false,
          actions: null,
        },
        hidden_fields: {
          editor: [],
          quickInfo: ['ResourceGroup_1'],
        },
        custom_form_settings: undefined as CustomFormSettings | undefined,
      } satisfies RcgView;

      const formSettings = await this.mapFormSettings(this.config()?.formSettings, this.config()?.eventData);
      view.custom_form_settings = formSettings;
      if (formSettings?.edit?.enabled) {
        view.acl.edit_series = true;
      }
      if (formSettings?.insert?.enabled) {
        view.acl.add = true;
      }
      return view;
    },
    { initialValue: null },
  );

  private async mapFormSettings(
    settings: CalendarFormSettings | undefined,
    eventPaths: MapEventData | undefined,
  ): Promise<CustomFormSettings | undefined> {
    if (!settings || !settings.formId) return undefined;
    if (!settings.edit?.enabled && !settings.insert?.enabled) return undefined;
    const result: CustomFormSettings = {
      formId: settings.formId,
    };

    if (settings.insert?.enabled && eventPaths?.start && eventPaths.end) {
      result.insert = {
        icon: settings.insert?.icon ?? 'add',
        enabled: true,
        dialogTitle: settings.insert?.dialogTitle
          ? await firstValueFrom(tr(settings.insert.dialogTitle))
          : await firstValueFrom(tr('add_event')),
        startDatePath: eventPaths.start,
        endDatePath: eventPaths.end,
        prefill: settings.insert.prefill,
      };
    }

    if (settings.edit?.enabled) {
      result.edit = {
        icon: settings.edit?.icon ?? 'edit',
        enabled: true,
        dialogTitle: settings.edit?.dialogTitle
          ? await firstValueFrom(tr(settings.edit?.dialogTitle))
          : await firstValueFrom(tr('edit_event')),
        prefill: settings.edit.prefill,
      };
    }
    return result.insert || result.edit ? result : undefined;
  }
}
